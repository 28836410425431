<template>
  <div v-if="dataCartAnonymous" class="col-12">
    <div
      v-if="dataCartAnonymous.carrito.giftcard"
      class="position-relative mb-4"
    >
      <div
        v-if="dataCartAnonymous.carrito.carrito_bono_codigo"
        class="summary-payment-gift-card"
      >
        <p>
          <b>Saldo a favor: </b>
          {{ dataCartAnonymous.carrito.carrito_bono_codigo }}
          <span class="float-end"
            >${{
              new Intl.NumberFormat('de-DE').format(
                dataCartAnonymous.carrito.carrito_bono_valor_aplicado,
              )
            }}</span
          >
        </p>
        <p class="mb-4">
          El valor del saldo es de ${{
            new Intl.NumberFormat('de-DE').format(
              dataCartAnonymous.carrito.carrito_bono_valor,
            )
          }}
          y se aplicara a tu compra un valor de ${{
            new Intl.NumberFormat('de-DE').format(
              dataCartAnonymous.carrito.carrito_bono_valor_aplicado,
            )
          }}
        </p>
        <div
          class="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center"
        >
          <p class="mb-0">
            <span
              class="custom-link-style mb-4 mb-md-0 text-center text-md-start w-100"
              @click="removeBono({ token: $route.params.tokenCart })"
              >Remover</span
            >
          </p>
          <button
            v-if="dataCartAnonymous.carrito.carrito_total == 0"
            class="btn-primary btn-finalize-payment"
            type=""
            @click="goPayBono()"
          >
            Finalizar compra
          </button>
        </div>
        <div v-if="msgErrorCheckout" class="mt-4 text-center">
          <span class="msgError">{{ msgErrorCheckout }}</span>
        </div>
      </div>
      <div v-else>
        <div class="d-flex position-relative">
          <button
            class="btn-show-gift-card"
            @click="showGiftCard = !showGiftCard"
          >
            {{
              showGiftCard
                ? 'Cerrar ventana'
                : '¿Tienes un saldo a favor / bono de regalo?'
            }}
          </button>
        </div>
        <div v-if="showGiftCard" class="container-gift-card">
          <div
            v-b-toggle.accordion-5
            :disabled="!dataCartAnonymous.carrito.giftcard"
            @click="optionSelect == 6 ? (optionSelect = 0) : (optionSelect = 6)"
          >
            <p class="content-payment-method">
              <span class="">
                <img src="@/assets/icons/gif-card.svg" style="width: 30px" />
              </span>
              <span class="payment-method-text">
                Saldo a favor / Bono de regalo
              </span>
            </p>
          </div>
          <payment-bill-gift-card-anonymo
            v-if="dataCartAnonymous.carrito.giftcard"
            @nextStep="$emit('nextStep')"
          />
        </div>
      </div>
    </div>
    <div
      v-if="dataCartAnonymous.carrito.mostrar_aviso_fe"
      class="alert alert-warning mt-2 d-flex flex-wrap mb-4"
    >
      <label class="d-flex mx-0 ms-md-1">
        <input
          v-model="esFELocal"
          class="tyc-checkbox"
          type="checkbox"
          checkmark="checkmark"
        />
        <span class="checkmark"></span>
      </label>
      <p class="mb-0" style="font-size: 1rem">
        ¿Desea recibir la <b>factura electrónicamente</b>?
      </p>
    </div>
    <div class="col-12 mx-auto accordion" role="tablist">
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.cupo ? '' : 'opacity: .4'"
          :class="
            optionSelect == 1 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-1
            @click="
              dataCartAnonymous.carrito.cupo
                ? optionSelect == 1
                  ? (optionSelect = 0)
                  : (optionSelect = 1)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 1 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 1 ? 'payment-method-icon-selected' : ''"
                style="background-color: #7633FF;"
              >
                <img
                  src="@/assets/icons/tipoPago/LogoAnim.gif"
                  style="max-width: 60px"
                />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Cupo personal Quac
                </p>
                <span class="payment-method-subtext">
                  Paga en hasta 10 cuotas quincenales
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 1"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha ms-0"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.cupo"
          id="accordion-1"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <payment-bill-cupo-anonymo
            :re-send-credit-cupo="reSendCreditCupo"
            @nextStep="$emit('nextStep')"
          />
        </b-collapse>
      </b-card>
      <b-card
        v-if="dataCartAnonymous.carrito.bancolombia"
        no-body
        class="card-select-payment-method"
      >
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.bancolombia ? '' : 'opacity: .4'"
          :class="
            optionSelect == 2 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-2
            @click="
              dataCartAnonymous.carrito.bancolombia
                ? optionSelect == 2
                  ? (optionSelect = 0)
                  : (optionSelect = 2)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 2 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 2 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-bancolombia.svg" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Bancolombia
                </p>
                <span class="payment-method-subtext">
                  Transfiere desde tu cuenta Bancolombia
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 2"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.pse"
          id="accordion-2"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <p>
              Usa tu cuenta de ahorros o corriente, recuerda que debes tener
              activo el servicio de clave dinámica de la App Bancolombia.
              Asegúrate de tener ${{
                new Intl.NumberFormat('de-DE').format(
                  dataCartAnonymous.carrito.carrito_total,
                )
              }}
              para finalizar tu compra.
            </p>
            <button
              class="btn-primary btn-finalize-payment"
              @click="payBancolombia()"
            >
              Pagar con Bancolombia
            </button>
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.pse ? '' : 'opacity: .4'"
          :class="
            optionSelect == 3 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-3
            @click="
              dataCartAnonymous.carrito.pse
                ? optionSelect == 3
                  ? (optionSelect = 0)
                  : (optionSelect = 3)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 3 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 3 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-pse.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  PSE
                </p>
                <span class="payment-method-subtext">
                  Transferencia electrónica
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 3"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.pse"
          id="accordion-3"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-bill-pay-u-anonymo @nextStep="$emit('nextStep')" />
          </div>
        </b-collapse>
      </b-card>
      <b-card
        v-if="dataCartAnonymous.carrito.addi"
        no-body
        class="card-select-payment-method"
      >
        <b-card-header
          class="card-header-payment-method"
          :class="
            optionSelect == 5 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-5
            @click="
              dataCartAnonymous.carrito.addi
                ? optionSelect == 5
                  ? (optionSelect = 0)
                  : (optionSelect = 5)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 5 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 5 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-addi.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  PSE o Addi
                </p>
                <span class="payment-method-subtext">
                  Paga a cuotas sin interés
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 5"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.addi"
          id="accordion-5"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-bill-addi-anonymo />
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.efectivo ? '' : 'opacity: .4'"
          :class="
            optionSelect == 4 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-4
            @click="
              dataCartAnonymous.carrito.efectivo
                ? optionSelect == 4
                  ? (optionSelect = 0)
                  : (optionSelect = 4)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 4 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 4 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-efecty.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Efecty
                </p>
                <span class="payment-method-subtext">
                  Paga en efectivo en puntos efecty
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 4"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.efectivo"
          id="accordion-4"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-bill-efecty-baloto-anonymo @nextStep="$emit('nextStep')" />
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.sistecredito ? '' : 'opacity: .4'"
          :class="
            optionSelect == 7 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-7
            @click="
              dataCartAnonymous.carrito.sistecredito
                ? optionSelect == 7
                  ? (optionSelect = 0)
                  : (optionSelect = 7)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 7 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 7 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/sistecredito.svg" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Sistecredito
                </p>
                <span class="payment-method-subtext">
                  Paga a cuotas con Sistecrédito
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 7"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.sistecredito"
          id="accordion-7"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div>
            <div class="payment-upon-delivery">
              <p class="text-center">
                Al seleccionar este medio de pago ten en cuenta que: Podrás
                realizar pagos desde $30.000, debes tener tu crédito al día,
                debes tener tu celular a la mano para enviarte el código de
                verificación y serás redirigido a la página de Sistecrédito.
              </p>
              <div class="d-flex justify-content-center mb-4">
                <button
                  class="btn-primary btn-finalize-payment"
                  type=""
                  @click="!loading ? paySistecredito() : ''"
                >
                  Pagar con Sistecrédito
                </button>
              </div>
              <div v-if="errorPaymentSistecredito" class="text-center">
                <span class="msgError">{{ errorPaymentSistecredito }}</span>
              </div>
            </div>
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="
            dataCartAnonymous.carrito.tarjeta_credito ? '' : 'opacity: .4'
          "
          :class="
            optionSelect == 6 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.accordion-6 @click="selectCreditCard()">
            <div
              class="content-payment-method"
              :class="optionSelect == 6 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 6 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-tarjeta.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Tarjeta crédito / débito
                </p>
                <span class="payment-method-subtext">
                  Recibimos todas las tarjetas
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 6"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.tarjeta_credito"
          id="accordion-6"
          v-model="isCreditCard"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-bill-credit-card-anonymo
              :is-selected="isCreditCard"
              @nextStep="$emit('nextStep')"
            />
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="dataCartAnonymous.carrito.contraentrega ? '' : 'opacity: .4'"
          :class="
            optionSelect == 8 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-8
            @click="
              dataCartAnonymous.carrito.contraentrega
                ? optionSelect == 8
                  ? (optionSelect = 0)
                  : (optionSelect = 8)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 8 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 8 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-contra-entrega.svg" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Contra entrega
                  <span class="text-coming-soon">
                    Muy pronto
                  </span>
                </p>
                <span class="payment-method-subtext">
                  Paga al momento de recibir
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 8"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCartAnonymous.carrito.contraentrega"
          id="accordion-8"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div>
            <div class="payment-upon-delivery">
              <p>
                Paga contra entrega en efectivo, ten la cantidad de dinero
                exacta de tu pedido ya que el transportador no tiene cambio, no
                olvides que es importante evitar al máximo el contacto cuando te
                entreguen tus productos. No realizamos entregas los domingos y
                días festivos.
              </p>
              <div class="d-flex justify-content-center mb-4">
                <button
                  class="btn-primary btn-finalize-payment"
                  type=""
                  @click="!loading ? payContraEntrega() : ''"
                >
                  Finalizar compra
                </button>
              </div>
              <div v-if="msgErrorCheckout" class="text-center">
                <span class="msgError">{{ msgErrorCheckout }}</span>
              </div>
            </div>
          </div>
        </b-collapse>
      </b-card>
    </div>
    <b-modal
      id="modal-error-406"
      centered
      hide-header
      no-close-on-backdrop
      hide-footer
    >
      <div class="text-center p-4">
        <p class="mx-4 mx-md-5 mb-4">{{ msgModalExcepcion406 }}</p>
        <div class="d-flex justify-content-center">
          <button
            v-if="btnModalExcepcion406 == 'bonoRegalo'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="goPayBono()"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'controEntrega'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="PaymentUponDelibery({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'efectyBaloto'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="paymentEfectyBaloto({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'pse'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="payPSE({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'creditCard'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="paymentCreditcard({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'cupo'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="reSendCreditCupo += 1"
          >
            Continuar
          </button>
          <button
            style="min-width: 150px"
            class="secondary-button col-6 mx-2"
            @click="reloadPage()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BCard, BCardHeader, BCollapse } from 'bootstrap-vue'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'

import PaymentBillCupoAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillCupoAnonymo.vue'
import PaymentBillCreditCardAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillCreditCardAnonymo.vue'
import PaymentBillPayUAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillPayUAnonymo.vue'
import PaymentBillEfectyBalotoAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillEfectyBalotoAnonymo.vue'
import PaymentBillGiftCardAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillGiftCardAnonymo.vue'
import PaymentBillAddiAnonymo from '@/components/Payment/StepsAnonymo/PaymentBillAddiAnonymo.vue'

import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: {
    BCard,
    BCardHeader,
    BCollapse,
    PaymentBillCupoAnonymo,
    PaymentBillCreditCardAnonymo,
    PaymentBillPayUAnonymo,
    PaymentBillEfectyBalotoAnonymo,
    PaymentBillGiftCardAnonymo,
    PaymentBillAddiAnonymo,
  },
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      optionSelect: 0,
      msgError: '',
      loading: false,
      showGiftCard: false,
      reSendCreditCupo: 0,
      esFELocal: false,
      errorPaymentBancolombia: '',
      errorPaymentSistecredito: '',
      isCreditCard: false,
    }
  },
  computed: {
    ...mapState('payment', [
      'msgErrorCheckout',
      'dataSummaryPayment',
      'dataCartAnonymous',
      'btnModalExcepcion406',
      'msgModalExcepcion406',
      'esFE',
    ]),
    getDataAnalytics() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.dataCartAnonymous.productos.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    esFELocal() {
      this.changeEsFE(this.esFELocal)
    },
  },
  created() {
    document.title = 'Checkout medio de pago | DEREK Tienda Online'
    sendFBQEvent(this, 'AddPaymentInfo', {})
  },
  methods: {
    ...mapActions('payment', [
      'PaymentUponDelibery',
      'removeBono',
      'payWhitGiftCard',
      'paymentEfectyBaloto',
      'payPSE',
      'paymentCreditcard',
      'changeEsFE',
    ]),
    async paySistecredito() {
      this.loading = true
      this.errorPaymentSistecredito = ''
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.$store.state.loaderDerek = true
      let data = new FormData()
      data.append('token', this.$route.params.tokenCart)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/sistecredito/compra',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Sistecredito')
          GA4PurchaseAnonymous()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderDerek = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.msgModalExcepcion406 = err.response.data.errors.bono_regalo[0]
            this.btnModalExcepcion406 = 'sistecredito'
          } else {
            var messages = ``
            if (err.response.data.message) {
              messages = err.response.data.message
            } else {
              var obj = err.response.data.errors
              for (const prop in obj) {
                messages += `${obj[prop][0]}\n`
              }
            }
            this.errorPaymentSistecredito = messages
          }
        })
    },
    async payContraEntrega() {
      this.loading = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.$store.state.loaderDerek = true
      await this.PaymentUponDelibery({
        token: this.$route.params.tokenCart,
        esFE: this.esFE,
      })
        .then(() => {
          if (this.msgModalExcepcion406) {
            this.$bvModal.show('modal-error-406')
          } else if (!this.msgErrorCheckout) {
            sendFBQEvent(this, 'Purchase', {
              value: this.dataCartAnonymous.carrito.carrito_total,
              currency: 'COP',
              contents: this.dataSummaryPayment.productos_purchase,
              content_ids: this.dataSummaryPayment.productos_purchase.map(
                e => e.id,
              ),
              event_id: this.dataSummaryPayment.pedido.pedido
                .pedido_reference_sale,
              content_type: 'product',
            })
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 't'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                  navigator.userAgent,
                )
              ? 'm'
              : 'd'
            window.criteo_q.push(
              { event: 'setAccount', account: 67539 },
              {
                event: 'setEmail',
                email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
              },
              { event: 'setSiteType', type: deviceType },
              { event: 'setZipcode', zipcode: '##Zip Code##' },
              {
                event: 'trackTransaction',
                id: this.dataSummaryPayment.pedido.carrito.reference_sale,
                item: this.getDataProductsCRTO,
              },
            )
            this.$emit('nextStep')
            this.$emit('nextStep')
          }
          this.$store.state.loaderDerek = false
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          this.$store.state.loaderDerek = false
          this.loading = false
        })
    },
    async payBancolombia() {
      this.$store.state.loaderDerek = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.errorPaymentBancolombia = ''
      let data = new FormData()
      data.append('token', this.$route.params.tokenCart)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/bancolombia/compra',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Bancolombia')
          GA4PurchaseAnonymous()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderDerek = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.msgModalExcepcion406 = err.response.data.errors.bono_regalo[0]
            this.btnModalExcepcion406 = 'pse'
          } else {
            this.errorPaymentBancolombia = err.response.data.response.msg
          }
        })
    },
    async goPayBono() {
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.$store.state.loaderDerek = true
      await this.payWhitGiftCard({ token: this.$route.params.tokenCart })
        .then(() => {
          if (this.msgModalExcepcion406) {
            this.$bvModal.show('modal-error-406')
          } else if (this.msgModalExcepcion406) {
            this.$bvModal.show('modal-error-406')
          } else if (!this.msgErrorCheckout) {
            sendFBQEvent(this, 'Purchase', {})
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 't'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                  navigator.userAgent,
                )
              ? 'm'
              : 'd'
            window.criteo_q.push(
              { event: 'setAccount', account: 67539 },
              {
                event: 'setEmail',
                email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
              },
              { event: 'setSiteType', type: deviceType },
              { event: 'setZipcode', zipcode: '##Zip Code##' },
              {
                event: 'trackTransaction',
                id: this.dataSummaryPayment.pedido.carrito.reference_sale,
                item: this.getDataProductsCRTO,
              },
            )
            this.$emit('nextStep')
            this.$emit('nextStep', 1)
          }
          this.$store.state.loaderDerek = false
        })
        .catch(() => {
          this.$store.state.loaderDerek = false
        })
    },
    selectCreditCard() {
      this.dataCartAnonymous.carrito.tarjeta_credito
        ? this.optionSelect == 6
          ? (this.optionSelect = 0)
          : (this.optionSelect = 6)
        : ''
    },
  },
}
</script>
